import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import Login from "./screens/Login";
import Chat2 from "./screens/Chat_";
import Chat from "./screens/Chat";
import Home from "./screens/Home";
import Verify from "./screens/Verify";
import Notifications from "./screens/Messaging/Notifications";
import Passphrase from "./screens/Passphrase_"; // Import the Passphrase component
import "./App.css";
import { OrgProvider } from "./contexts/org-context";
import { MessagingProvider } from "./contexts/messaging-context";
import { SubOrgProvider } from "./contexts/suborg-items-context";
import Notification from "./screens/Messaging/Notification";
import WidgetAuth from "./screens/Widget/WidgetAuth";

const ProtectedRoute = ({ component: Component }) => {
  const { org_slug = "reverb" } = useParams(); // Accessing the parameter
  const sessionToken = localStorage.getItem(`${org_slug}_sessionToken`); // SERGIO - Changed to localStorage
  return sessionToken ? <Component /> : <Navigate to={`/${org_slug}/login`} />;
};

const RedirectIfLoggedIn = ({ component: Component }) => {
  const { org_slug = "reverb" } = useParams(); // Accessing the parameter
  const sessionToken = localStorage.getItem(`${org_slug}_sessionToken`); // SERGIO - Changed to localStorage

  return sessionToken ? <Navigate to={`/${org_slug}/chat`} /> : <Component />;
};

const WidgetRoute = ({ component: Component }) => {
  return <Component />;
};

function App() {
  return (
    <Router>
      <div className="App">
        <OrgProvider>
          <MessagingProvider>
            <SubOrgProvider>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/reverb/login" replace />}
                />
                <Route
                  path="/:org_slug"
                  element={<RedirectIfLoggedIn component={Login} />}
                />
                <Route
                  path="/:org_slug/login"
                  element={<RedirectIfLoggedIn component={Login} />}
                />
                <Route path="/:org_slug/verify" element={<Verify />} />
                <Route
                  path="/:org_slug/passphrase"
                  element={<RedirectIfLoggedIn component={Passphrase} />}
                />
                <Route
                  path="/:org_slug/home"
                  element={<ProtectedRoute component={Home} />}
                />
                <Route
                  path="/:org_slug/chat"
                  element={<ProtectedRoute component={Chat} />}
                />
                <Route
                  path="/:org_slug/chat/:sub_org_slug"
                  element={<ProtectedRoute component={Chat} />}
                />
                <Route
                  path="/:org_slug/chat2"
                  element={<ProtectedRoute component={Chat2} />}
                />
                <Route
                  path="/:org_slug/notifications"
                  element={<ProtectedRoute component={Notifications} />}
                />
                <Route
                  path="/:org_slug/notification/:post_id"
                  element={<ProtectedRoute component={Notification} />}
                />
                <Route
                  path="/:org_slug/widget"
                  element={<WidgetRoute component={WidgetAuth} />}
                />
                <Route
                  path="/:org_slug/widget/:sub_org_slug"
                  element={<WidgetRoute component={WidgetAuth} />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/reverb/login" replace />}
                />
              </Routes>
            </SubOrgProvider>
          </MessagingProvider>
        </OrgProvider>
      </div>
    </Router>
  );
}

export default App;
