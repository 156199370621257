import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "src/api/api";
import { createSSERagQuery } from "src/api/chat";
import { useOrgContext } from "src/contexts/org-context";
import { formatMessage } from "src/helpers/format-message";
import { useConfigFromApi } from "src/helpers/load-org-from-api";
import { BASE_URL } from "src/config";
import "./Widget.css";
import { fetchOrgConfig } from "src/api/org";

enum MessageSender {
  Bot = "bot",
  User = "user",
}

interface Message {
  id: number;
  message: string;
  sender: MessageSender;
  isProcessing?: boolean;
  docs: string[];
  imagesLoading: boolean;
}

const Widget = () => {
  const { org_slug = "" , sub_org_slug = "" } = useParams();
  const [messages, setMessages] = useState<Message[]>([]);
  const messageInputRef = useRef<HTMLDivElement>(null);
  const messagesListRef = useRef<HTMLDivElement>(null);
  const api = useApi(org_slug);
  const { configLoaded } = useConfigFromApi(org_slug, true);
  const { bot_greeting_msg, query_type, primary_color } = useOrgContext();
  const [queryType, setQueryType] = useState(query_type);

  // Add welcome message
  useEffect(() => {
    if (configLoaded && messages.length === 0) {
      setMessages([
        {
          id: 1,
          message: bot_greeting_msg || "Hello, how can I help you today?",
          sender: MessageSender.Bot,
          docs: [],
          imagesLoading: false,
        },
      ]);
    }
  }, [configLoaded, bot_greeting_msg, messages.length]);

  useEffect(() => {
    // If a suborg slug is provided, fetch the suborg config, to determine the query type
    if (sub_org_slug && sub_org_slug.length > 0) {
      fetchOrgConfig(sub_org_slug, false)
        .then((data) => {
          if (data?.config) {
            setQueryType(data.config.query_type);
          }
        })
        .catch((error) => {
          console.error("Error fetching suborg config:", error);
        });
    }
  }, [sub_org_slug]);

  const handleKeyDown = useRef((event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleMessage();
    }
  });

  // Bind listener to message input
  useEffect(() => {
    const element = messageInputRef.current;
    const _handleKeyDown = handleKeyDown.current;
    if (element) {
      element.addEventListener("keydown", _handleKeyDown);
      return () => element.removeEventListener("keydown", _handleKeyDown);
    }

    return () => { };
  }, []);


  const sendMessage = useCallback(
    async (message: string) => {
      if (!message || message.trim() === "") return;

      // Get current slug from URL path
      const pathParts = window.location.pathname.split('/');
      const currentSubOrgSlug = pathParts.length >= 4 ? pathParts[3] : "";

      // Check for qdrant parameter
      const useQdrant = new URLSearchParams(window.location.search).get('qdrant') === '1';

      setMessages((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          message: message,
          sender: MessageSender.User,
          docs: [],
          imagesLoading: false,
        },
        {
          id: prev.length + 2,
          message: "",
          sender: MessageSender.Bot,
          isProcessing: true,
          docs: [],
          imagesLoading: false,
        },
      ]);

      try {
        const response = await createSSERagQuery(
          api,
          currentSubOrgSlug.length > 0 ? currentSubOrgSlug : org_slug,
          message,
          useQdrant // Pass qdrant parameter to API call
        );

        const eventSource = new EventSource(`${BASE_URL}${response.stream_url}`);
        let incomingMessage = "";

        eventSource.addEventListener("message", (event) => {
          if (event.data === "[DONE]") {
            eventSource.close();
            setMessages((prev) => {
              const lastMessage = prev[prev.length - 1];
              if (lastMessage.sender === MessageSender.Bot) {
                lastMessage.message = incomingMessage;
                lastMessage.isProcessing = false;
              }
              return [...prev];
            });
          } else {
            incomingMessage += formatMessage(event.data);
            setMessages((prev) => {
              const lastMessage = prev[prev.length - 1];
              if (lastMessage.sender === MessageSender.Bot) {
                lastMessage.message = incomingMessage;
                lastMessage.isProcessing = false;
              }
              return [...prev];
            });
          }
        });

        eventSource.addEventListener("error", (error) => {
          console.error("Error:", error);
          eventSource.close();
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
    [api, org_slug]
  );

  const determineSendStyle = (message_length: number, qt: string) => {
    if (message_length > 1) {
      return "bg-primary shadow-md";
    }

    if (qt === "VEC") {
      return "bg-none";
    } else {
      return "bg-[#D0DADB] shadow-md";
    }
  };

  const handleMessage = useCallback(() => {
    const messageInput = messageInputRef.current;

    if (messageInput) {
      const text = messageInput.outerText;
      
      // Add the message to the chat
      sendMessage(text);

      // Clear the input
      messageInput.innerHTML = "";
    }
  }, [sendMessage]);

  const beginAudio = useCallback(() => {
    console.log("beginAudio");
  }, []);

  return (
    <div className="widget-container" style={{ "--primary-color": primary_color } as any}>
      <div className="widget-messages" ref={messagesListRef}>
        {messages.map((message) => (
          <div
            key={message.id}
            className={`widget-message ${
              message.sender === MessageSender.User ? "user" : "bot"
            }`}
          >
            <div className="message-content">
              {message.isProcessing ? (
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
              )}
            </div>
          </div>
        ))}
      </div>
      
      <div
          className={`bg-white min-h-14 p-2 flex shadow-xl border-solid border-t-2 border-[#E7EAEB]`}
        >
          <div
            className="h-9 align-middle chat-input flex-1 bg-[#F1F4F5] rounded-lg border-solid border-2 border-[#E7EAEB]"
            data-placeholder="Reply to TeamMate"
            ref={messageInputRef}
            contentEditable
          ></div>
          <button
            className={`rounded-full ${determineSendStyle(
              (messageInputRef.current &&
                messageInputRef.current.outerText &&
                messageInputRef.current.outerText.length) ||
                0,
              queryType || "",
            )} w-9 h-9 flex items-center justify-center mx-2`}
            onClick={
              (messageInputRef.current &&
                messageInputRef.current.outerText.length > 1) ||
              queryType !== "VEC"
                ? handleMessage
                : beginAudio
            }
          >
            {(messageInputRef.current &&
              messageInputRef.current.outerText.length > 1) ||
            queryType !== "VEC" ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14.5C7 15.0523 7.44772 15.5 8 15.5C8.55228 15.5 9 15.0523 9 14.5L7 14.5ZM8.70711 0.792893C8.31658 0.402369 7.68342 0.402369 7.29289 0.792893L0.928932 7.15685C0.538408 7.54738 0.538408 8.18054 0.928932 8.57107C1.31946 8.96159 1.95262 8.96159 2.34315 8.57107L8 2.91421L13.6569 8.57107C14.0474 8.96159 14.6805 8.96159 15.0711 8.57107C15.4616 8.18054 15.4616 7.54738 15.0711 7.15685L8.70711 0.792893ZM9 14.5L9 1.5L7 1.5L7 14.5L9 14.5Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="22.5"
                height="26.25"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.25 6C3.25 2.82436 5.82436 0.25 9 0.25C12.1756 0.25 14.75 2.82436 14.75 6V9C14.75 12.1756 12.1756 14.75 9 14.75C5.82436 14.75 3.25 12.1756 3.25 9V6ZM9 1.75C6.65279 1.75 4.75 3.65279 4.75 6V9C4.75 11.3472 6.65279 13.25 9 13.25C11.3472 13.25 13.25 11.3472 13.25 9V6C13.25 3.65279 11.3472 1.75 9 1.75ZM7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H10C10.4142 5.25 10.75 5.58579 10.75 6C10.75 6.41421 10.4142 6.75 10 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6ZM1 7.25C1.41421 7.25 1.75 7.58579 1.75 8V9C1.75 13.0041 4.99594 16.25 9 16.25C13.0041 16.25 16.25 13.0041 16.25 9V8C16.25 7.58579 16.5858 7.25 17 7.25C17.4142 7.25 17.75 7.58579 17.75 8V9C17.75 13.5798 14.2314 17.3379 9.75 17.7183V20C9.75 20.4142 9.41421 20.75 9 20.75C8.58579 20.75 8.25 20.4142 8.25 20V17.7183C3.7686 17.3379 0.25 13.5798 0.25 9V8C0.25 7.58579 0.585786 7.25 1 7.25ZM6.25 9C6.25 8.58579 6.58579 8.25 7 8.25H11C11.4142 8.25 11.75 8.58579 11.75 9C11.75 9.41421 11.4142 9.75 11 9.75H7C6.58579 9.75 6.25 9.41421 6.25 9Z"
                  fill="#031618"
                />
              </svg>
            )}
          </button>
        </div>
    </div>
  );
};

export default Widget; 