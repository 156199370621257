import { BASE_URL } from 'src/config';
import { AxiosInstance } from 'axios';

export const postChatMessage = async (messageText: string, org_slug: string) => {
  const token = localStorage.getItem(`${org_slug}_sessionToken`);
  console.log('Posting chat message:', messageText);
  console.log('Authentication token:', token);

  try {
    const response = await fetch(`${BASE_URL}/${org_slug}/rag/sse-query`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: messageText }),
    });

    console.log('Response status:', response.status);

    if (!response.ok) {
      if (response.status === 401) {
        console.log('Unauthorized access, redirecting to login');
        localStorage.removeItem(`${org_slug}_sessionToken`);
        localStorage.removeItem(`${org_slug}_sessionPhone`);
        window.location.href = `/${org_slug}/login`;
      } else {
        console.log('Failed to post query, response status:', response.status);
        throw new Error('Failed to post query');
      }
    }

    const responseData = await response.json();
    console.log('Response data:', responseData);

    // Access the relevant property from the server response
    const generatedResponse = responseData.response;

    return generatedResponse;
  } catch (error) {
    console.error('Error posting chat message:', error);
    throw error;
  }
};

export const createSSERagQuery = async (
  api: AxiosInstance,
  org_slug: string,
  prompt: string,
  useQdrant: boolean = false
) => {
  const endpoint = useQdrant ? `${org_slug}/rag2/sse-query` : `${org_slug}/rag/sse-query`;
  const response = await api.post(endpoint, {
    prompt
  });
  return response.data;
};

export const getQueryHistory = async (api: AxiosInstance, org_slug: string, user_id: string) => {
  try {
    const response = await api.get(`${org_slug}/reporting/user-reports/${user_id}`);

    return response.data;
  } catch (error) {
    console.error('Error getting query history:', error);
    throw error;
  }
};

export const getQuizQuestion = async (api: AxiosInstance, org_slug: string) => {
  try {
    const response = await api.get(`/org/${org_slug}/quiz-questions`);
    return response.data;
  } catch (error) {
    console.error('Error getting quiz question:', error);
    throw error;
  }
};

export const fetchDocumentUrl = async (api: AxiosInstance, org_slug: string, filename: string) => {
  try {
    const response = await api.post(`/${org_slug}/rag/get-url`, {
      filename
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching document URL:', error);
    throw error;
  }
}