import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loginAsGuest } from "src/api/auth";
import Widget from "./Widget";

const WidgetAuth = () => {
  const { org_slug = "" } = useParams();
  const [loggedIn, setLoggedIn] = useState(false);

  // Attempt guest login on mount
  useEffect(() => {
    const attemptGuestLogin = async () => {
      try {
        const attempt = await loginAsGuest(org_slug!);
        if (attempt) {
          setLoggedIn(true);
        }
      } catch (e) {
        console.error('Failed to login as guest:', e);
      }
    };

    attemptGuestLogin();
  }, [org_slug]);

  // Show loader if not logged in
  if (!loggedIn) {
    return <div>Loading...</div>;
  }

  // Show widget if logged in
  return <Widget />;
};

export default WidgetAuth;